// used to construct the list of all pairs we consider by default in the frontend

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C',
  '0x174b0662f587B7786b46366Cd054d25DE2Cf6510', // ky doggo
  '0x8468Af2Fd1309995732b297196Fb8923b17896B9', //
  '0xF74D144b82e606444d03bC0f958D68168313897b', // shibaspark.eth
  '0x933ae37b8D15ACeD2FD1584E52f0cf3E67b349d0', // ky doggo
  '0xa8A5550E7866E8FfFE41B197B5E6d60236865034'
]

export const BLOCK_MESSAGES: Record<string, string> = {
'0xa8A5550E7866E8FfFE41B197B5E6d60236865034': 'Not authorized due to inaction on part of project owner, please contact Jim to resolve',
}

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 100
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export { default as ifosConfig } from './ifo'
export { default as poolsConfig } from './pools'

export const FAST_INTERVAL = 10000
export const SLOW_INTERVAL = 60000

export const NOT_ON_SALE_SELLER = '0x0000000000000000000000000000000000000000'
export const NO_PROXY_CONTRACT = '0x0000000000000000000000000000000000000000'

export const FARM_AUCTION_HOSTING_IN_SECONDS = 604800

export const PREDICTION_TOOLTIP_DISMISS_KEY = 'prediction-switcher-dismiss-tooltip'

// Gelato uses this address to define a native currency in all chains
export const GELATO_NATIVE = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

export const EXCHANGE_DOCS_URLS = 'https://docs.chewyswap.dog/'
