import { DNSConnect } from "@webinterop/dns-connect";

const dnsConnect = new DNSConnect();

const supportedChains: Record<number, string> = {
  109: "BONE",
};

const fallbackChainId = 109;

export const resolve = async (
  name: string,
  chainId: number = fallbackChainId
): Promise<string | undefined> => {
  let chainName = "BONE";
  if (supportedChains[chainId] !== undefined) {
    chainName = supportedChains[chainId];
    // throw new Error(`Chain ${chainId} is not supported`);
  }
  const response = await dnsConnect.resolve(name, chainName);
  return response ?? undefined;
};

export const reverseResolve = async (
  address: string,
  chainId: number = fallbackChainId
): Promise<string | undefined> => {
  let chainName = "BONE";
  if (supportedChains[chainId] !== undefined) {
    chainName = supportedChains[chainId];
    // throw new Error(`Chain ${chainId} is not supported`);
  }
  const domainName = await dnsConnect.reverseResolve(
    address,
    chainName
  );
  if (domainName === null) {
    return undefined;
  }
  return domainName ?? undefined;
};
