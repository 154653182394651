import { dogechainTokens } from '@pancakeswap/tokens'
import { PoolCategory, SerializedPoolConfig } from 'config/constants/types'

export const livePools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: dogechainTokens.wojk,
    earningToken: dogechainTokens.wojk,
    contractAddress: '0x065AAE6127D2369C85fE3086b6707Ac5dBe8210a',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    isFinished: false,
  },
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

const finishedPools = [
  {
    sousId: 1,
    stakingToken: dogechainTokens.wojk,
    earningToken: dogechainTokens.tdh,
    contractAddress: '0xa137fc1a0a8062500aef1abb1b825d7c271d97ab',
    version: 1,
    isFinished: true,
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '5.79',
  },
  {
    sousId: 2,
    stakingToken: dogechainTokens.wojk,
    earningToken: dogechainTokens.tdh,
    contractAddress: '0x4a4ecbdcff1cbfc71dd7bd721105a4f2c06b4164',
    enableEmergencyWithdraw: true,
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.4756',
    isFinshed: true,
  },
].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export const pools: SerializedPoolConfig[] = [...livePools, ...finishedPools]
