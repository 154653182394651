const CHEWYLIST = 'https://raw.githubusercontent.com/ChewySwap/chewy-tokenlist/main/tokenLists/ChewyTokenList.json'

// List of official tokens list
export const OFFICIAL_LISTS = [CHEWYLIST]

export const UNSUPPORTED_LIST_URLS: string[] = []
export const WARNING_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  CHEWYLIST,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
  ...WARNING_LIST_URLS,
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [CHEWYLIST]
