import { BigNumber, FixedNumber } from '@ethersproject/bignumber'

export const FIXED_ZERO = FixedNumber.from(0)
export const FIXED_ONE = FixedNumber.from(1)
export const FIXED_TWO = FixedNumber.from(2)

export const BIG_TEN = BigNumber.from(10)
export const FIXED_TEN_IN_POWER_18 = FixedNumber.from(BIG_TEN.pow(18))

export const masterChefAddresses = {
  2000: '0x4c00f75F179F4A8208BC2ba3958Eb8d1C7986418',
  109: '0x4c00f75F179F4A8208BC2ba3958Eb8d1C7986418',
}

export const nonBSCVaultAddresses = {}
