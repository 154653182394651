import { ChainId, Token } from '@pancakeswap/sdk'

export const CHEWY_BSC = new Token(
  ChainId.BSC,
  '0x2761723006d3Eb0d90B19B75654DbE543dcd974f',
  18,
  'CHEWY',
  'ChewySwap',
  'https://chewyswap.dog/',
)

export const CHEWY_BSC_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x60cd6352Ba5065E0A26BC5Fb0B6B6212DF8Ad9d9',
  18,
  'CHEWY',
  'ChewySwap Testnet',
  'https://chewyswap.dog/',
)

export const CHEWY_SHIBARIUM = new Token(
  ChainId.SHIBARIUM,
  '0x2761723006d3Eb0d90B19B75654DbE543dcd974f',
  18,
  'CHEWY',
  'ChewySwap',
  'https://chewyswap.dog/',
)

export const DOGESHREK = new Token(
  ChainId.DOGECHAIN,
  '0x2BE0096B24343549E34224aa9aa297E99961023D',
  18,
  'DOGESHREK',
  'DogeShrek',
  'https://dogeshrek.com',
)

export const USDC_BSC = new Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDT_SHIB = new Token(
  ChainId.SHIBARIUM,
  '0xaB082b8ad96c7f47ED70ED971Ce2116469954cFB',
  6,
  'USDT',
  'Tether USD',
)

export const USDC_SHIB = new Token(
  ChainId.SHIBARIUM,
  '0xf010f12dcA0b96D2d6685bf4dB3dbB4Ad500B6Ad',
  6,
  'USDC',
  'Shibarium USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_DC = new Token(ChainId.DOGECHAIN, '0xD95086Fe465a2DC59F989B927472a901E2e05ff2', 18, 'USDO', 'USD Coin')

export const USDT_DC = new Token(
  ChainId.DOGECHAIN,
  '0xD95086Fe465a2DC59F989B927472a901E2e05ff2',
  18,
  'USDO',
  'Tether USD',
)

export const USDT_ETH = new Token(
  ChainId.ETH,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'Tether USD',
)

export const USDT_BSC = new Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_BSC = new Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x8516Fc284AEEaa0374E66037BD2309349FF728eA',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_DC = new Token(
  ChainId.DOGECHAIN,
  '0x332730a4F6E03D9C55829435f10360E13cfA41Ff',
  18,
  'BUSD',
  'BUSD Token',
  'https://www.paxos.com/busd/',
)

export const BUSD: Record<ChainId, Token> = {
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
  [ChainId.SHIBARIUM]: USDT_SHIB,
  [ChainId.DOGECHAIN]: USDT_DC,
  [ChainId.ETH]: USDT_ETH,
}

export const CHEWY = {
  [ChainId.BSC]: CHEWY_BSC,
  [ChainId.BSC_TESTNET]: CHEWY_BSC_TESTNET,
  [ChainId.SHIBARIUM]: CHEWY_SHIBARIUM,
  [ChainId.DOGECHAIN]: DOGESHREK,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.SHIBARIUM]: USDT_SHIB,
  [ChainId.DOGECHAIN]: USDT_DC,
  [ChainId.ETH]: USDT_ETH,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.SHIBARIUM]: USDT_SHIB,
  [ChainId.DOGECHAIN]: USDT_DC,
}
