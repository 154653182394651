import { mainnet, goerli } from 'wagmi/chains'
import { Chain } from 'wagmi'


const bscExplorer = { name: 'BscScan', url: 'https://bscscan.com' }
const shibExplorer = { name: 'ShibariumScan', url: 'https://www.shibariumscan.io' }
const dcExplorer = { name: 'DogeChain Explorer', url: 'https://explorer.dogechain.dog' }

export const bsc: Chain = {
  id: 56,
  name: 'BNB Smart Chain',
  network: 'bsc',
  rpcUrls: {
    default: {
      http: ['https://bsc-dataseed1.binance.org'],
    },
    public: {
      http: ['https://bsc-dataseed1.binance.org'],
    }
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer,
  },
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 15921452,
    },
  }
}

export const eth: Chain = {
  id: 1,
  name: 'Ethereum',
  network: 'eth',
  rpcUrls: {
    default: {
      http: ['https://eth.llamarpc.com'],
    },
    public: {
      http: ['https://eth.llamarpc.com'],
    }
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer,
  },
  nativeCurrency: {
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 14353601,
    },
  }
}

export const bscTest: Chain = {
  id: 157,
  name: 'Puppynet Testnet',
  network: 'bsc-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'BONE',
    symbol: 'BONE',
  },
  rpcUrls: {
    default: {
      http: ['https://puppynet.shibrpc.com'],
    },
    public: {
      http: ['https://puppynet.shibrpc.com'],
    }
  },
  blockExplorers: {
    default: { name: 'Puppynet Testnet Explorer', url: 'https://puppyscan.shib.io/' },
  },
  contracts: {
    multicall3: {
      address: '0xA4029b74FBA366c926eDFA7Dd10B21C621170a4c',
      blockCreated: 3035769,
    },
  },
  testnet: true,
}

export const dogechain: Chain = {
  id: 2000,
  name: 'DogeChain',
  network: 'dogechain',
  rpcUrls: {
    default: {
      http: ['https://rpc.dogechain.dog'],
    },
    public: {
      http: ['https://rpc.dogechain.dog'],
    }
  },
  blockExplorers: {
    default: dcExplorer,
    etherscan: dcExplorer,
  },
  nativeCurrency: {
    name: 'Wrapped DOGE',
    symbol: 'DOGE',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0x321Bf4EF31c87CF6E23E779db9C5F806A37d695F',
      blockCreated: 1419910,
    },
  },
}

export const shibarium: Chain = {
  id: 109,
  name: 'Shibarium',
  network: 'shibarium',
  rpcUrls: {
    default: {
      http: ['https://www.shibrpc.com'],
    },
    public: {
      http: ['https://www.shibrpc.com'],
    }
  },
  blockExplorers: {
    default: shibExplorer,
    etherscan: shibExplorer,
  },
  nativeCurrency: {
    name: 'Wrapped BONE',
    symbol: 'BONE',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xB585B461950c4A684aDB06f8F03eB1c3410ED2c5',
      blockCreated: 791891,
    },
  },
}

export { mainnet, goerli }
