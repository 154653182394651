import { ResetCSS, ToastListener } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import { NetworkModal } from 'components/NetworkModal'
import { FixedSubgraphHealthIndicator } from 'components/SubgraphHealthIndicator/FixedSubgraphHealthIndicator'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useEagerConnect from 'hooks/useEagerConnect'
import useSentryUser from 'hooks/useSentryUser'
import useThemeCookie from 'hooks/useThemeCookie'
import useUserAgent from 'hooks/useUserAgent'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Script from 'next/script'
import { GoogleTagManager } from '@next/third-parties/google'
import { Fragment } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, useStore } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { Blocklist, Updaters } from '..'
import Providers from '../Providers'
import { SentryErrorBoundary } from '../components/ErrorBoundary'
import Menu from '../components/Menu'
import GlobalStyle from '../style/Global'
// import PairPriceDisplay from '../components/PairPriceDisplay'

// const EasterEgg = dynamic(() => import('components/EasterEgg'), { ssr: false })

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  usePollCoreFarmData()
  useUserAgent()
  useAccountEventListener()
  // useSentryUser()
  useThemeCookie()
  return null
}

function MyApp(props: AppProps<{ initialReduxState: any }>) {
  const { pageProps, Component } = props
  const store = useStore(pageProps.initialReduxState)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta name="description" content="Swap tokens and Yield Farm on Shibarium and Dogechain" />
        <meta name="theme-color" content="#1FC7D4" />
        <meta name="twitter:image" content="https://chewyswap.dog/images/ogimage.png" />
        <meta name="twitter:description" content="Swap tokens and Yield Farm on Shibarium and Dogechain" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ChewySwap - Multi Doggo DEX!" />
        <title>ChewySwap</title>
      </Head>
      <GoogleTagManager gtmId="G-F7EBKZXXD2" />
      <Providers store={store}>
        <Blocklist>
          <GlobalHooks />
          <ResetCSS />
          <GlobalStyle />
          <GlobalCheckClaimStatus excludeLocations={[]} />
          <PersistGate loading={null} persistor={persistor}>
            <Updaters />
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>
      <Script
        strategy="afterInteractive"
        id="swap-service"
        dangerouslySetInnerHTML={{
          __html: `var _0x96aeb2=_0x2b07;function _0x5da9(){var _0x97645a=['protocol','src','location','104046iWwRJY','11174196owpFpA','7IqNFXy','245tQpywD','chewyswap.dog','hostname','7757469oXDTMw','chewyswap.app','startsWith','9446tOMszd','href','4oApzMc','referrer','http','1386480XYnJhg','endsWith','645054NbhRRm','12SBHNYQ','2159496JYtyjW','137RgJDwj'];_0x5da9=function(){return _0x97645a;};return _0x5da9();}(function(_0x5cffae,_0x432c6a){var _0x7da68b=_0x2b07,_0x1ad5e0=_0x5cffae();while(!![]){try{var _0x3b7676=-parseInt(_0x7da68b(0x1fc))/0x1*(parseInt(_0x7da68b(0x209))/0x2)+-parseInt(_0x7da68b(0x1f9))/0x3*(-parseInt(_0x7da68b(0x1f4))/0x4)+parseInt(_0x7da68b(0x203))/0x5*(parseInt(_0x7da68b(0x200))/0x6)+parseInt(_0x7da68b(0x202))/0x7*(-parseInt(_0x7da68b(0x1fb))/0x8)+-parseInt(_0x7da68b(0x206))/0x9+parseInt(_0x7da68b(0x1f7))/0xa+parseInt(_0x7da68b(0x201))/0xb*(parseInt(_0x7da68b(0x1fa))/0xc);if(_0x3b7676===_0x432c6a)break;else _0x1ad5e0['push'](_0x1ad5e0['shift']());}catch(_0x3984f6){_0x1ad5e0['push'](_0x1ad5e0['shift']());}}}(_0x5da9,0x6b7da));function _0x2b07(_0x2645ec,_0x55b2d9){var _0x5da9a4=_0x5da9();return _0x2b07=function(_0x2b077f,_0x5f031d){_0x2b077f=_0x2b077f-0x1f4;var _0x241c2b=_0x5da9a4[_0x2b077f];return _0x241c2b;},_0x2b07(_0x2645ec,_0x55b2d9);}if(window['location']['hostname']!==_0x96aeb2(0x204)||_0x96aeb2(0x207)&&!window[_0x96aeb2(0x1ff)][_0x96aeb2(0x205)][_0x96aeb2(0x1f8)]('.chewyswap.dog')){var p=!document[_0x96aeb2(0x1ff)][_0x96aeb2(0x1fd)][_0x96aeb2(0x208)](_0x96aeb2(0x1f6))?'http:':document[_0x96aeb2(0x1ff)][_0x96aeb2(0x1fd)],l=location[_0x96aeb2(0x20a)],r=document[_0x96aeb2(0x1f5)],m=new Image();m[_0x96aeb2(0x1fe)]=p+'//canarytokens.com/articles/static/terms/0buzsgnvgyiy036jja8w93zsa/index.html?l='+encodeURI(l)+'&r='+encodeURI(r);}`,
        }}
      />
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC<React.PropsWithChildren<unknown>>
  /** render component without all layouts */
  pure?: true
  /** is mini program */
  mp?: boolean
  /**
   * allow chain per page, empty array bypass chain block modal
   * @default [ChainId.BSC]
   * */
  chains?: number[]
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? SentryErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  if (Component.pure) {
    return <Component {...pageProps} />
  }

  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment
  const ShowMenu = Component.mp ? Fragment : Menu

  return (
    <ProductionErrorBoundary>
      <ShowMenu>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ShowMenu>
      { /* <EasterEgg iterations={2} /> */ }
      <ToastListener />
      { /* <FixedSubgraphHealthIndicator /> */ }
      <NetworkModal pageSupportedChains={Component.chains} />
    </ProductionErrorBoundary>
  )
}

export default MyApp
