import { getFarmsPriceHelperLpFiles } from '@pancakeswap/farms/constants/priceHelperLps/getFarmsPriceHelperLpFiles'
import { ChainId } from '@pancakeswap/sdk'
import PoolsDogeChainPriceHelper from './pools/2000'
import PoolsShibariumPriceHelper from './pools/109'
import PoolsBscPriceHelper from './pools/56'
import PoolsBscTestnetPriceHelper from './pools/97'

export { getFarmsPriceHelperLpFiles }

export const getPoolsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.BSC:
      return PoolsBscPriceHelper
    case ChainId.BSC_TESTNET:
      return PoolsBscTestnetPriceHelper
    case ChainId.DOGECHAIN:
      return PoolsDogeChainPriceHelper
    case ChainId.SHIBARIUM:
      return PoolsShibariumPriceHelper
    default:
      return []
  }
}
