import { ContextApi } from '@pancakeswap/localization'
import { FooterLinkType } from '@pancakeswap/uikit'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = () => [
  // {
  //   label: t('About'),
  //   items: [
  //     {
  //       label: t('Contact'),
  //       href: 'https://docs.wojak.fi/contact-us',
  //       isHighlighted: true,
  //     },
  //     {
  //       label: t('Brand'),
  //       href: 'https://docs.wojak.fi/brand',
  //     },
  //     // {
  //     //   label: t('Blog'),
  //     //   href: 'https://medium.com/pancakeswap',
  //     // },
  //     // {
  //     //   label: t('Community'),
  //     //   href: 'https://docs.wojak.fi/contact-us/telegram',
  //     // },
  //     // {
  //     //   label: t('Litepaper'),
  //     //   href: 'https://v2litepaper.wojak.fi/',
  //     // },
  //     // {
  //     //   label: '—',
  //     // },
  //     // {
  //     //   label: t('Online Store'),
  //     //   href: 'https://pancakeswap.creator-spring.com/',
  //     // },
  //   ],
  // },
  // {
  //   label: t('Help'),
  //   items: [
  //     {
  //       label: t('Customer Support'),
  //       href: 'https://docs.wojak.fi/contact-us/customer-support',
  //     },
  //     {
  //       label: t('Troubleshooting'),
  //       href: 'https://docs.wojak.fi/help/troubleshooting',
  //     },
  //     {
  //       label: t('Guides'),
  //       href: 'https://docs.wojak.fi/get-started',
  //     },
  //   ],
  // },
  // {
  //   label: t('Developers'),
  //   items: [
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/pancakeswap',
  //     },
  //     // {
  //     //   label: t('Documentation'),
  //     //   href: 'https://docs.wojak.fi',
  //     // },
  //     // {
  //     //   label: t('Bug Bounty'),
  //     //   href: 'https://docs.wojak.fi/code/bug-bounty',
  //     // },
  //     // {
  //     //   label: t('Audits'),
  //     //   href: 'https://docs.wojak.fi/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited',
  //     // },
  //     // {
  //     //   label: t('Careers'),
  //     //   href: 'https://docs.wojak.fi/hiring/become-a-chef',
  //     // },
  //   ],
  // },
]
