import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { VaultKey } from 'state/types'

export const getAddress = (address: Address | string, chainId?: number): string => {
  return typeof address === 'string' ? address : address[chainId || ChainId.SHIBARIUM]
}

export const getMasterChefAddress = (chainId?: number) => {
  return getAddress(addresses.masterChef, chainId)
}
export const getMasterChefV1Address = (chainId?: number) => {
  return getAddress(addresses.masterChefV1, chainId)
}
export const getMulticallAddress = (chainId?: number) => {
  return getAddress(addresses.multiCall, chainId)
}
export const getLotteryV2Address = (chainId?: number) => {
  return getAddress(addresses.lotteryV2, chainId)
}
export const getPancakeProfileAddress = (chainId?: number) => {
  return getAddress(addresses.pancakeProfile, chainId)
}
export const getPancakeBunniesAddress = (chainId?: number) => {
  return getAddress(addresses.pancakeBunnies, chainId)
}
export const getBunnyFactoryAddress = (chainId?: number) => {
  return getAddress(addresses.bunnyFactory, chainId)
}
export const getPredictionsV1Address = (chainId?: number) => {
  return getAddress(addresses.predictionsV1, chainId)
}
export const getClaimRefundAddress = (chainId?: number) => {
  return getAddress(addresses.claimRefund, chainId)
}
export const getPointCenterIfoAddress = (chainId?: number) => {
  return getAddress(addresses.pointCenterIfo, chainId)
}
export const getBunnySpecialAddress = (chainId?: number) => {
  return getAddress(addresses.bunnySpecial, chainId)
}
export const getTradingCompetitionAddressEaster = (chainId?: number) => {
  return getAddress(addresses.tradingCompetitionEaster, chainId)
}
export const getTradingCompetitionAddressFanToken = (chainId?: number) => {
  return getAddress(addresses.tradingCompetitionFanToken, chainId)
}

export const getTradingCompetitionAddressMobox = (chainId?: number) => {
  return getAddress(addresses.tradingCompetitionMobox, chainId)
}

export const getTradingCompetitionAddressMoD = (chainId?: number) => {
  return getAddress(addresses.tradingCompetitionMoD, chainId)
}

export const getEasterNftAddress = (chainId?: number) => {
  return getAddress(addresses.easterNft, chainId)
}

export const getVaultPoolAddress = (vaultKey: VaultKey, chainId?: number) => {
  if (!vaultKey) {
    return null
  }
  return getAddress(addresses[vaultKey], chainId)
}

export const getCakeVaultAddress = (chainId?: number) => {
  return getAddress(addresses.cakeVault, chainId)
}

export const getCakeFlexibleSideVaultAddress = (chainId?: number) => {
  return getAddress(addresses.cakeFlexibleSideVault, chainId)
}

export const getBunnySpecialCakeVaultAddress = (chainId?: number) => {
  return getAddress(addresses.bunnySpecialCakeVault, chainId)
}
export const getBunnySpecialPredictionAddress = (chainId?: number) => {
  return getAddress(addresses.bunnySpecialPrediction, chainId)
}
export const getBunnySpecialLotteryAddress = (chainId?: number) => {
  return getAddress(addresses.bunnySpecialLottery, chainId)
}
export const getBunnySpecialXmasAddress = (chainId?: number) => {
  return getAddress(addresses.bunnySpecialXmas, chainId)
}
export const getFarmAuctionAddress = (chainId?: number) => {
  return getAddress(addresses.farmAuction, chainId)
}
export const getAnniversaryAchievement = (chainId?: number) => {
  return getAddress(addresses.AnniversaryAchievement, chainId)
}

export const getNftMarketAddress = (chainId?: number) => {
  return getAddress(addresses.nftMarket, chainId)
}
export const getNftSaleAddress = (chainId?: number) => {
  return getAddress(addresses.nftSale, chainId)
}
export const getPancakeSquadAddress = (chainId?: number) => {
  return getAddress(addresses.pancakeSquad, chainId)
}
export const getPotteryDrawAddress = (chainId?: number) => {
  return getAddress(addresses.potteryDraw, chainId)
}

export const getZapAddress = (chainId?: number) => {
  return getAddress(addresses.zap, chainId)
}
export const getICakeAddress = (chainId?: number) => {
  return getAddress(addresses.iCake, chainId)
}

export const getBCakeFarmBoosterAddress = (chainId?: number) => {
  return getAddress(addresses.bCakeFarmBooster, chainId)
}

export const getBCakeFarmBoosterProxyFactoryAddress = () => {
  return getAddress(addresses.bCakeFarmBoosterProxyFactory)
}
