import { ContextApi } from '@pancakeswap/localization'
import {
  DropdownMenuItemType,
  EarnFillIcon,
  EarnIcon,
  MenuItemsType,
  MoreIcon,
  PoolFillIcon,
  PoolIcon,
  StakingFillIcon,
  StakingIcon,
  SwapFillIcon,
  SwapIcon,
} from '@pancakeswap/uikit'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_ONLY_SHIB } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Exchange'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: false,
        supportChainIds: SUPPORT_ONLY_SHIB,
        items: [].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('Liquidity'),
        icon: PoolIcon,
        fillIcon: PoolFillIcon,
        href: '/liquidity',
        showItemsOnMobile: false,
        supportChainIds: SUPPORT_ONLY_SHIB,
        items: [].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('Earn'),
        href: 'https://app.chewyswap.dog/farms',
        icon: EarnIcon,
        fillIcon: EarnFillIcon,
        showItemsOnMobile: true,
        hideSubNav: true,
        image: '/images/decorations/pe2.png',
        // supportChainIds: SUPPORT_ONLY_SHIB,
        items: [
          {
            label: `Shibarium ${t('Farms')}`,
            href: 'https://app.chewyswap.dog/farms',
            // type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: `Dogechain ${t('Farms')}`,
            href: 'https://farms.dogeshrek.com/farms',
            // type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: `${t('Legacy Farms')}`,
            href: 'https://farms.chewyswap.dog/',
            // type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      /* {
        label: t('Stake'),
        href: 'https://farms.chewyswap.dog/treatbowl',
        icon: StakingIcon,
        fillIcon: StakingFillIcon,
        showItemsOnMobile: false,
        image: '/images/decorations/pe2.png',
        supportChainIds: SUPPORT_ONLY_SHIB,
        items: [].map((item) => addMenuItemSupported(item, chainId)),
      }, */
      {
        label: '',
        // href: '/info',
        icon: MoreIcon,
        hideSubNav: true,
        items: [
          {
            label: t('Bondle.xyz - Launch Tokens'),
            href: 'https://www.bondle.xyz/',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Chart'),
            href: 'https://dexscreener.com/shibarium/chewyswap',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: `${t('Bridge')} - Shibarium (Official)`,
            href: 'https://shibarium.shib.io/bridge',
            // type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: `${t('Bridge')} - Hoichi`,
            href: 'https://bridge.hoichitoken.io/',
            // type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: `${t('Bridge')} - Dogechain`,
            href: 'https://dive.dogechain.dog/bridge',
            // type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Docs'),
            href: 'https://docs.chewyswap.com',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('KYC'),
            href: 'https://assuredefi.com/projects/chewyswap/',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },

    ].map((item) => addMenuItemSupported(item, chainId))

export default config
